<template>
  <footer
    data-scroll-section
    class="relative z-20 overflow-hidden pt-14 bg-basic_gray"
  >
    <div class="w-full max-w-screen-xl px-5 mx-auto xl:px-0 sm:px-10">
      <div class="flex flex-wrap justify-between w-full lg:flex-nowrap">
        <div
          class="w-full mb-5 text-2xl font-bold text-center text-black sm:mb-0 sm:text-left sm:w-auto"
        >
          <h4>泳欣電商平台</h4>
          <p class="text-xs text-primary font-anybody">YongXin Shop</p>
        </div>
        <ol class="w-full mb-10 text-center lg:mb-14 sm:text-left sm:w-auto">
          <li class="mb-1 font-medium font-anybody">
            <span class="block mr-4 font-bold sm:inline">公司地址 </span>
            {{ $GetColumn('company_address') }}
          </li>
          <li class="font-medium font-anybody">
            <span class="block mr-4 font-bold sm:inline">聯絡電話 </span>
            {{ $GetColumn('company_phone') }}
          </li>
        </ol>
      </div>
    </div>

    <div class="w-full pt-4 pb-32 bg-black md:pb-4">
      <div
        class="flex flex-wrap items-center justify-center w-full max-w-screen-xl px-5 mx-auto md:justify-between xl:px-0 sm:px-10"
      >
        <ol
          class="flex items-center justify-center mb-5 text-sm md:mb-0 md:justify-start"
        >
          <li class="">
            <router-link
              class="text-white transition-colors duration-500 hover:text-primary"
              to="/terms/site_privacy"
              >隱私權政策</router-link
            >
          </li>
          <!-- <li class="mr-5">
            <router-link
              class="text-white transition-colors duration-500 hover:text-primary"
              to="/terms/terms_of_customer"
              >會員條款</router-link
            >
          </li>
          <li>
            <router-link
              class="text-white transition-colors duration-500 hover:text-primary"
              to="/order_search/search"
              >訂單查詢</router-link
            >
          </li> -->
        </ol>
        <p
          class="w-full text-xs text-center md:w-auto font-anybody text-basic_gray md:text-right"
        >
          Copyright Yongxin All Rights Reserved.<br class="block md:hidden" />
          網頁設計：<a
            href="https://www.yongxin-design.com"
            target="_blank"
            class="text-primary"
            >泳欣事業有限公司</a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MainFooter',
  data() {
    return {
      menu_list: [
        {
          title: 'About',
          link: '/about',
        },
        {
          title: 'Products',
          link: '/collections',
        },
        {
          title: 'News',
          link: '/news',
        },
        {
          title: 'Dealer',
          link: '/dealer',
        },
        {
          title: 'Contact',
          link: '/contact',
        },
        {
          title: 'Member',
          link: '/account/login',
        },
      ],
    };
  },
  methods: {
    ScrollToTop() {
      this.$emit('scroll-top');
    },
  },
  mounted() {},
};
</script>
