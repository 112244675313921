<template>
  <header class="fixed top-0 left-0 right-0 z-30">
    <div class="relative z-20 flex items-center w-full px-2 py-4">
      <div
        class="relative z-10 flex items-center justify-between w-full px-2 mx-auto sm:px-5 lg:px-14"
      >
        <router-link to="/" class="text-2xl font-bold text-white">
          <h1>泳欣電商平台</h1>
          <p class="text-xs text-primary font-anybody">YongXin Shop</p>
        </router-link>

        <div class="flex items-center">
          <button
            @click="OpenShopcart"
            class="relative flex items-center px-4 py-2 text-sm font-bold text-white transition-colors duration-300 border rounded-full md:py-3 md:px-6 md:text-base border-primary md:hover:bg-opacity-40 bg-primary"
          >
            立即結帳
            <span
              class="flex items-center justify-center w-5 h-5 ml-2 bg-black rounded-full"
            >
              <p class="text-xs font-bold text-white transform scale-75">
                {{ shopcart_count }}
              </p>
            </span>
            <!-- <span class="text-2xl text-white icon-cart"></span> -->
          </button>
        </div>
      </div>
    </div>

    <MainMenu ref="MainMenu" :menu_list="menu_list" />
  </header>
</template>

<script>
import MainMenu from '@/components/MainMenu.vue';
export default {
  name: 'MainHeader',
  components: {
    MainMenu,
  },
  data() {
    return {
      menu_list: [
        {
          title: 'About',
          sub_title: '關於我們',
          link: '/about',
        },
        {
          title: 'Products',
          sub_title: '商品列表',
          link: '/collections',
        },
        {
          title: 'News',
          sub_title: '最新消息',
          link: '/news',
        },
        {
          title: 'Dealer',
          sub_title: '經銷列表',
          link: '/dealer',
        },
        {
          title: 'Contact',
          sub_title: '聯絡我們',
          link: '/contact',
        },
        // {
        //   title: 'Member',
        //   link: '/account/login',
        // },
      ],
    };
  },
  methods: {
    OpenMenu() {
      this.$refs.MainMenu.Open();
    },
    CloseMenu() {
      this.$refs.MainMenu.Close();
    },
    OpenShopcart() {
      this.$store.commit('SetShopcartDrawer', true);
    },
    OpenSearchDialog() {
      this.$store.commit('SetSearchDialog', true);
    },
  },
  computed: {
    shopcart() {
      return this.$store.state.shopcart_module.shopcart;
    },
    shopcart_count() {
      let count = 0;
      this.shopcart.forEach((item) => {
        count += parseInt(item.amount);
      });

      return count;
    },
  },
};
</script>

// logo_box

<style scoped>
.logo_box {
  -webkit-clip-path: polygon(
    100% 0,
    calc(100% - 80px) 100%,

    calc(100% - 80px) 100%,
    0 100%,

    0 100%,
    0 0%
  );
  clip-path: polygon(
    100% 0,
    calc(100% - 80px) 100%,

    calc(100% - 80px) 100%,
    0 100%,

    0 100%,
    0 0%
  );
}

@media (max-width: 1024px) {
  .logo_box {
    -webkit-clip-path: polygon(
      100% 0,
      calc(100% - 54px) 100%,

      calc(100% - 54px) 100%,
      0 100%,

      0 100%,
      0 0%
    );
    clip-path: polygon(
      100% 0,
      calc(100% - 54px) 100%,

      calc(100% - 54px) 100%,
      0 100%,

      0 100%,
      0 0%
    );
  }
}
</style>
