import store from '@/store';

export function ConvertProductData(product_data) {
  let gtm_product = {
    item_id: product_data.GoodsID,
    item_name: product_data.Title,
    item_brand: GetCompanyName(),
    price: parseInt(GetLowPrice(product_data.Stock)),
    item_variant: GetVariant(product_data.Stock),
    item_list_id:
      product_data.Menu.length > 0 ? product_data.Menu[0].MenuID : '',
    index: product_data.Seq,
    currency: 'TWD',
  };

  for (let i = 1; i <= 5; i++) {
    if (product_data.Menu.length >= i) {
      i == 1
        ? (gtm_product.item_category = product_data.Menu[i - 1].Title)
        : (gtm_product[`item_category${i}`] = product_data.Menu[i - 1].Title);
    }
  }
  return gtm_product;
}

function GetCustomProductPrice(product_data, active_options) {
  let change_price = 0;
  // 讀取客製化商品的『價格異動資料』陣列
  product_data.CustomGoodsChangePrice.forEach((change_item) => {
    let match_count = 0;
    let change_id_list = change_item.CustomSpecID;
    // 若『價格異動資料』類行為字串，則先分割為陣列
    if (typeof change_item.CustomSpecID === 'string') {
      change_id_list = change_item.CustomSpecID.split(',');
    }

    // 判斷與目前選擇之選項是否完全相符
    change_id_list.forEach((id) => {
      active_options.indexOf(id) != -1 ? (match_count += 1) : '';
    });

    // 若『價格異動資料』的選項ID陣列與目前選擇之選項ID完全相符，則紀錄異動價錢
    if (match_count == change_id_list.length) {
      change_price += parseInt(change_item.ChangePrice);
    }
  });

  // 返回三種價格：1.原價+異動價格 2.售價+異動價格 3.會員價+異動價格
  return [
    parseInt(product_data.CustomGoodsStock[0].Price) + change_price,
    parseInt(product_data.CustomGoodsStock[0].SellPrice) + change_price,
    parseInt(product_data.CustomGoodsStock[0].MemberSellPrice) + change_price,
  ];
}

export function ConvertAddShopCartData(product_data, active_options, amount) {
  let variant_text = '';
  let price = 0;

  // 若為客製化商品則將『商品規格』全數帶入
  if (product_data.IsCustom == 'Y') {
    active_options.forEach((option, option_index) => {
      option_index != 0 ? (variant_text += ',') : '';
      product_data.CustomSpecList.forEach((item) => {
        item.CustomSpecID == option ? (variant_text += item.SpecTitle) : '';
      });
    });
    price = GetCustomProductPrice(product_data, active_options);
  } else {
    const ColorTitle = product_data.Stock.filter(
      (item) => item.ColorID == active_options[0]
    )[0].ColorTitle;
    const SizeTitle = product_data.Stock.filter(
      (item) => item.SizeID == active_options[1]
    )[0].SizeTitle;
    variant_text = `${ColorTitle},${SizeTitle}`;
    price = product_data.Stock.filter(
      (item) =>
        item.SizeID == active_options[1] && item.ColorID == active_options[0]
    )[0].SellPrice;
  }

  let gtm_product = {
    item_id: product_data.GoodsID,
    item_name: product_data.Title,
    quantity: amount,
    item_brand: GetCompanyName(),
    price: parseInt(price),
    item_variant: variant_text,
    item_list_id:
      product_data.Menu.length > 0 ? product_data.Menu[0].MenuID : '',
    index: product_data.Seq,
    currency: 'TWD',
  };

  for (let i = 1; i <= 5; i++) {
    if (product_data.Menu.length >= i) {
      i == 1
        ? (gtm_product.item_category = product_data.Menu[i - 1].Title)
        : (gtm_product[`item_category${i}`] = product_data.Menu[i - 1].Title);
    }
  }
  return gtm_product;
}

export function GetCompanyName() {
  const column = store.state.common_column_data.filter(
    (column) => column.Title == 'brand_name'
  );
  return column.length <= 0 ? '' : column[0].Content;
}

export function GetLowPrice(stocks) {
  let price = 9999;
  stocks.forEach((item) => {
    parseInt(item.SellPrice) < price ? (price = item.SellPrice) : '';
  });
  return price == 9999 ? 0 : price;
}

export function GetVariant(stocks) {
  return stocks.length > 0
    ? `${stocks[0].ColorTitle},${stocks[0].SizeTitle}`
    : '';
}

export function GTM_AddPaymentInfo(shopcart, payment_id) {
  const payment_type = store.state.payment_data.filter(
    (item) => item.PaymentID == payment_id
  )[0];

  let products = [];

  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });

  window.dataLayer.push({
    event: 'addPaymentInfo',
    items: products,
    value: 0,
    currency: 'TWD',
    payment_type: payment_type.Title,
  });
}

export function GTM_AddShipInfo(shopcart, shipping_id, ship_price) {
  const shipway = store.state.shipway_data.filter(
    (item) => item.ShippingID == shipping_id
  )[0];

  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });

  window.dataLayer.push({
    event: 'addShippingInfo',
    items: products,
    value: 0,
    currency: 'TWD',
    shipping: ship_price,
    shipping_tier: shipway.Title,
  });
}

export function GTM_BeginCheckout(shopcart) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      1
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'beginCheckout',
    items: products,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_Purchase(shopcart, trade_data, coupon_info) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      item.amount
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'purchase',
    trade_no: trade_data.TradeID,
    items: products,
    value: trade_data.Price,
    currency: 'TWD',
    tax: 0,
    shipping: trade_data.ShippingFee,
    coupon: coupon_info,
  });
}

export function GTM_AddShopCart(shopcart_item, amount) {
  window.dataLayer.push({
    event: 'add_to_cart',
    items: [
      ConvertAddShopCartData(
        shopcart_item.product_data,
        shopcart_item.active_option,
        amount
      ),
    ],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_RemoveShopCart(shopcart_item, amount) {
  window.dataLayer.push({
    event: 'remove_from_cart',
    items: [
      ConvertAddShopCartData(
        shopcart_item.product_data,
        shopcart_item.active_option,
        amount
      ),
    ],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_OpenShopCart(shopcart) {
  let products = [];
  shopcart.forEach((item) => {
    const product = ConvertAddShopCartData(
      item.product_data,
      item.active_option,
      1
    );
    products.push(product);
  });
  window.dataLayer.push({
    event: 'view_cart',
    items: products,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_ViewProduct(product_data) {
  window.dataLayer.push({
    event: 'viewProduct',
    items: [ConvertProductData(product_data)],
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_ViewItemList(category_title, category_id) {
  window.dataLayer.push({
    event: 'viewItemList',
    item_list_name: category_title,
    item_list_id: category_id,
    value: 0,
    currency: 'TWD',
  });
}

export function GTM_CustomEvent(event_name) {
  window.dataLayer.push({
    event: event_name,
  });
}
